import scriptExists from "./scriptExists";

function initRecaptcha(options) {
  const recaptcha = {
    captchaId: options.captchaId || "",
    key: options.key || "",
    token: options.token || "",
    apiURL: options.apiURL || "",
    form: options.form || null,
    button: options.button || null,
    captchaRun: false,
    verificationCallBack: null,
    clientId: options.clientId || "",
    isPending: options.isPending || true,
    controlId: options.controlId || null,

    clearCallback() {
      recaptcha.callback = null;
    },

    getToken(returnedToken) {
      const hiddenInput = document.getElementById(
        `sw-g-recaptcha-response-${recaptcha.clientId}`
      );
      hiddenInput.value = returnedToken;
      if (recaptcha.callback) {
        recaptcha.callback(returnedToken);
      }
    },

    runCaptchaClientSide(cb, onError) {
      const validateCaptchaOnServer = (token) => {
        const handleError = (error) => {
          window.console.error("Error:", error);
          if (onError) {
            onError(error);
          }
        };

        const handleSuccess = () => {
          if (cb) {
            cb(token);
          }
        };

        const handleResponse = (resp) => {
          if (!resp.ok) {
            return handleError(resp.statusText);
          } else {
            return handleSuccess(resp.json());
          }
        };

        const resetCaptcha = () => grecaptcha.reset(recaptcha.captchaId);

        const url = `${recaptcha.apiURL}/sites/recaptcha/${recaptcha.siteId}?recaptcha_token=${token}`;
        return fetch(url, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        })
          .then(handleResponse)
          .catch(handleError)
          .then(resetCaptcha);
      };

      this.runCaptcha(validateCaptchaOnServer);
    },

    runCaptcha(cb) {
      recaptcha.callback = cb;
      grecaptcha.execute(recaptcha.captchaId);
    },

    buildCaptcha(divId) {
      recaptcha.captchaId = grecaptcha.render(`captcha-box-${divId}`, {
        sitekey: recaptcha.key,
        callback: recaptcha.getToken,
        size: "invisible",
      });

      let container = document.getElementsByName("aspnetForm");
      if (container.length === 0) {
        container = document.getElementsByTagName("body");
      }
      const hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("id", `sw-g-recaptcha-response-${divId}`);
      hiddenInput.setAttribute("name", `sw-g-recaptcha-response-${divId}`);
      container[0].append(hiddenInput);
      recaptcha.isPending = false;

      const event = new CustomEvent("SWReCaptchaLoaded", {
        detail: {
          clientId: divId,
        },
      });
      document.body.dispatchEvent(event);
    },

    waitForScriptAndLoad() {
      if (
        typeof grecaptcha === "undefined" ||
        typeof grecaptcha.render === "undefined"
      ) {
        setTimeout(recaptcha.waitForScriptAndLoad, 300, this);
      } else {
        recaptcha.buildCaptcha(recaptcha.clientId);
      }
    },

    reset() {
      return grecaptcha.reset(this.captchaId);
    },

    addRecaptchaScriptToHead(cb) {
      const googleScript =
        "https://www.google.com/recaptcha/api.js?render=explicit";
      if (!scriptExists(googleScript)) {
        const script = document.createElement("script");
        script.src = googleScript;
        document.head.appendChild(script);
      }
      cb();
    },
  };
  return recaptcha;
}

window.SWReCaptcha = {
  init: function (opts = {}) {
    var newRecaptcha = initRecaptcha({
      key: opts.siteKey,
      isPending: true,
      apiURL: opts.apiURL,
      siteId: opts.siteId,
      controlId: opts.controlId,
      clientId: opts.clientId,
    });
    newRecaptcha.addRecaptchaScriptToHead(() => {
      newRecaptcha.waitForScriptAndLoad();
    });
    return newRecaptcha;
  },
};

